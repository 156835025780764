<template>
  <el-scrollbar>
    <div class="page-container">
      <header>
        <header-bar/>
      </header>
      <div class="note-container">
        <el-row class="note-container-row">
          <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2"></el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="20" :xl="20">
            <div class="note-container-row-body">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>公告详情</el-breadcrumb-item>
              </el-breadcrumb>
              <div class="main-body" v-loading="detailLoading">
                <div class="note-body">
                  <div class="header" >
                    <h1>{{noteForm.noticeTitle}}</h1>
                    <div class="note-base-info">
                      <span class="publish-data">发布时间：{{noteForm.updateTime||noteForm.createTime||'未知'}}</span>
                      <span class="editor">发布人：{{noteForm.updateBy||noteForm.createBy}}</span>
                    </div>
                    <p v-if="noteForm.noticeBrief">{{noteForm.noticeBrief}}</p>
                  </div>
                  <article>
                    <div v-html="noteForm.noticeDetails"></div>
                    <!--        <tg-editor :html="noteForm.noticeDetails" :show-menu="false" :editor-config="editorConfig"></tg-editor>-->
                  </article>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2"></el-col>
        </el-row>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import HeaderBar from '@/components/header-bar/HeaderBar'
import { notificationDetail } from '@/service/api/notification/notification'
export default {
  name: 'notificationDetail',
  components: {
    HeaderBar
  },
  data() {
    return {
      detailLoading: false,
      noteForm: {},
      editorConfig: {
        readOnly: true
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail()
    } else {
      this.$router.push('/index')
    }
  },
  methods: {
    goBack() {
      this.$emit('changePage', { pageId: 'NotificationTable' })
    },
    getDetail() {
      this.detailLoading = true
      notificationDetail({ id: this.$route.query.id }).then(res => {
        this.detailLoading = false
        if (res.data.status === 200 && res.data.data) {
          this.noteForm = res.data.data
        } else {
          this.$router.push('/index')
        }
      }).catch(e => {
        console.log(e)
        this.detailLoading = false
        this.$router.push('/index')
        this.$notify.error({ title: '错误', message: '服务异常！', offset: 100 })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page-container{
  min-width: 768px;
  min-height: 100vh;
  box-sizing: border-box;
  header{
    height: 60px;
    width: 100%;
    min-width: 768px;
    position: fixed;
    background-color: #d3ddf0;
    z-index: 3000;
    .header-container{
      min-width: 768px;
    }
  }
  .note-container{
    width: 100%;
    min-width: 768px;
    height: 400px;
    .note-container-row{
      min-height: 400px;
      display: flex;
      align-items: center;
      .note-container-row-body{
        width: 100%;
        margin-top: 80px;
        .el-breadcrumb{
          padding-left: 60px;
        }
        .main-body{
          padding: 0 16px;
          box-sizing: border-box;
          overflow-y: auto;
          .note-body{
            min-height: 100px;
            background-color: #ffffff;
            box-sizing: border-box;
            padding: 30px 40px;
            .header{
              box-sizing: border-box;
              margin: 20px 0;
              h1{
                font-size: 34px;
                color: #000;
                margin: 0 0 20px;
                font-weight: 400;
              }
              .note-base-info{
                .publish-data{
                  font-size: 14px;
                  color: #999;
                }
                .editor{
                  font-size: 14px;
                  color: #999;
                  margin-left: 20px;
                }
              }
              p {
                padding: 18px;
                outline: 0;
                max-width: 100%;
                font-family: Avenir, -apple-system-font, 微软雅黑, sans-serif;
                color: rgb(89, 89, 89);
                background-color: rgb(239, 239, 239);
                visibility: visible;
                line-height: 1.75em;
                box-sizing: border-box !important;
                overflow-wrap: break-word !important;
              }
            }
            ::v-deep article{
              box-sizing: border-box;
              div{
                word-break: break-all;
                box-sizing: border-box;
                line-height: 1.75em;
                img{
                  width: 80%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep.el-scrollbar{
  height: 100vh;
  width: 100%;
  min-width: 768px;
  .el-scrollbar__wrap {
    min-width: 768px;
    overflow-x: hidden;
  }
}
</style>
