<template>
  <div class="header-container">
    <el-row>
      <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2"></el-col>
      <el-col :xs="22" :sm="22" :md="22" :lg="20" :xl="20">
        <div class="grid-content">
          <div class="left" @click="toIndex">
            <img src="../../assets/images/mgt-home/ic-logo.svg" alt="">
          </div>
          <div class="right">
            <!--            <el-input size="small" type="text" maxlength="20" placeholder="搜索" clearable v-model="searchInput"><el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button></el-input>-->
            <button class="login-btn" v-if="!isLogin" @click="login">登录</button>
            <el-dropdown trigger="hover" v-else @command="handleCommand" placement="bottom">
              <div class="user-dropdown">
                <span class="header-icon">{{ userinfo.nickname.slice(-2) }}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <!--                <el-dropdown-item command="1"  style="color: #003AFF;">个人中心</el-dropdown-item>-->
                <el-dropdown-item command="2" style="color: #003AFF;"
                                  v-if="userinfo&&userinfo.roles&&userinfo.roles.includes('admin') || userinfo.roles&&userinfo.roles.includes('announcement_admin') ">
                  管理后台
                </el-dropdown-item>
                <el-dropdown-item command="3" style="color: #003AFF;">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="hover" @command="handleCommandVPN" class="vpn" placement="bottom">
              <div class="vpn-dropdown">
                VPN下载
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="win" style="display: flex;align-items: center;gap:5px;color: #003AFF;"><img
                  src="@/assets/images/mgt-home/ic-win.webp" alt=""> windows
                </el-dropdown-item>
                <el-dropdown-item command="mac" style="display: flex;align-items: center;gap:5px;color: #003AFF;"><img
                  src="@/assets/images/mgt-home/ic-mac.webp" alt=""> mac
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-col>
      <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2"></el-col>
    </el-row>
  </div>
</template>

<script>
import authorization from '@/store/utils/authorization'
import loginApi from '@/service/api/login/login-api'

export default {
  name: 'HeaderBar',
  computed: {
    userinfo() {
      return authorization.getUserInfo() || {}
    }
  },
  data() {
    return {
      searchInput: '',
      isLogin: false
    }
  },
  mounted() {
    this.JudgeIsLogin()
  },
  methods: {
    // 判断登录状态
    async JudgeIsLogin() {
      const res = await loginApi.isLogin()
      this.isLogin = res.data.data
      if (!res.data.data) {
        authorization.clearAllCache()
      } else if (res.data.data && !(authorization.getUserInfo() && authorization.getToken())) {
        this.$router.push('/login')
      }
    },
    toIndex() {
      this.$router.push('/index')
    },
    // 登录
    login() {
      this.$router.push('/login')
    },
    // 头像下拉点击事件
    handleCommand(val) {
      if (val === '1') {
        return
      }
      if (val === '2') {
        this.$router.push(this.$store.getters.getFirstMenuPath).catch(() => {
        })
        return
      }
      if (val === '3') {
        this.$router.push('/logout')
      }
    },
    handleCommandVPN(val) {
      if (val === 'win') {
        window.open('https://pan.baidu.com/s/13JtdF1kbQo7SLzscUPrmOA?pwd=xxjr')
      } else if (val === 'mac') {
        window.open('https://pan.baidu.com/s/1fUjmhKY6XQ3sjYKGAxMBVw?pwd=bw6x ')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header-container {
  width: 100%;
  min-width: 768px;
  background-color: #FFFFFF;
  height: 60px;

  .el-row {
    height: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 6px #e9e9e9;

    .grid-content {
      display: flex;
      justify-content: space-between;

      .left {
        cursor: pointer;
        //img{
        //  width: 146px;
        //  height: 34px;
        //}
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        ::v-deep.el-input {
          .el-input__inner {
            border: 1px solid #D8E0E6;
            height: 30px;
            line-height: 30px;
          }

          .el-input-group__append {
            padding: 0 10px;
            background-color: unset;
            color: #000000;

            &:hover {
              background-color: #e8413d;
              color: #ffffff;
            }
          }
        }

        .login-btn {
          background: #FFFFFF;
          border: 1px solid rgba(21, 87, 255, 1);
          border-radius: 4px;
          width: 56px;
          height: 28px;
          box-sizing: border-box;
          font-size: 16px;
          color: #003AFF;
          letter-spacing: 0;
          font-weight: 400;
          margin-left: 20px;
          line-height: 28px;
          padding: 0;
          text-align: center;
        }

        ::v-deep.el-dropdown {
          margin-left: 20px;

          .vpn-dropdown {
            font-size: 16px;
            color: #003AFF;
            cursor: pointer;
          }

          .user-dropdown {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .header-icon {
              display: block;
              width: 36px;
              height: 36px;
              border-radius: 17px;
              background-size: cover;
              background: #4876FF no-repeat;
              color: #ffffff;
              text-align: center;
              line-height: 36px;
              font-size: 14px;
            }

            .nickname {
              max-width: 110px;
              margin-left: 10px;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
